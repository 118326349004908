import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { MenuItem, Subset } from '../navbars/menu-item';
import { SecurityRoleKey } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ClientAccountService {
  customMenu: Array<MenuItem>;
  flattenRequiredSecurityRoles;

  private menu: Array<MenuItem> = [
    {
      itemName: 'Home',
      itemIconClass: 'fa-solid fa-house-chimney fa-lg',
      itemRoute: '/home',
      routeIdentifier: 'HOME',
      minSecurityRole: SecurityRoleKey.User,
    },
    {
      itemName: 'My Details',
      itemRoute: '/person-details',
      routeIdentifier: 'MY-USER_PROFILE',
      minSecurityRole: SecurityRoleKey.User,
      hideItem: true,
    },
    {
      itemName: 'My Competencies',
      itemIconClass: 'fa-regular fa-address-card fa-lg',
      routeIdentifier: 'CLAIMS',
      minSecurityRole: SecurityRoleKey.User,
      subMenu: [
        {
          itemName: 'New assessment',
          itemRoute: '/claim/new',
          minSecurityRole: SecurityRoleKey.User,
          routeIdentifier: 'NEW',
        },

        {
          itemName: 'Action required',
          itemRoute: '/claims/claimant/actionRequired',
          minSecurityRole: SecurityRoleKey.User,
          routeIdentifier: 'ACTION_REQUIRED',
        },
        {
          itemName: 'With assessor',
          itemRoute: '/claims/claimant/withAssessor',
          minSecurityRole: SecurityRoleKey.User,
          routeIdentifier: 'WITH_ASSESSOR',
        },
        {
          itemName: 'Draft assessments',
          itemRoute: '/claims/claimant/draft',
          minSecurityRole: SecurityRoleKey.User,
          routeIdentifier: 'DRAFT',
        },
        {
          itemName: 'Published competencies',
          itemRoute: '/claims/claimant/published',
          minSecurityRole: SecurityRoleKey.User,
          routeIdentifier: 'PUBLISHED',
        },
        {
          itemName: 'Removed assessments',
          itemRoute: '/claims/claimant/removed',
          minSecurityRole: SecurityRoleKey.User,
          routeIdentifier: 'REMOVED',
        },
        {
          itemName: 'All assessments',
          itemRoute: '/claims/claimant/all',
          minSecurityRole: SecurityRoleKey.User,
          routeIdentifier: 'ALL_CLAIMS',
        },
      ],
    },
    {
      itemName: 'My Assessments',
      itemIconClass: 'fa-solid fa-briefcase fa-lg',
      routeIdentifier: 'ASSESSMENTS',
      minSecurityRole: SecurityRoleKey.Assessor,
      forAssessorOnly: true,
      subMenu: [
        {
          itemName: 'Action required',
          itemRoute: '/claims/assessor/actionRequired',
          minSecurityRole: SecurityRoleKey.Assessor,
          routeIdentifier: 'ASSESSOR_ACTION_REQUIRED',
          claimCategory: 'VERIFIED',
        },
        {
          itemName: 'With claimant',
          itemRoute: '/claims/assessor/withClaimant',
          minSecurityRole: SecurityRoleKey.Assessor,
          routeIdentifier: 'ASSESSOR_WITH_CLAIMANT',
          claimCategory: 'VERIFIED',
        },
        {
          itemName: 'Published assessments',
          itemRoute: '/claims/assessor/published',
          minSecurityRole: SecurityRoleKey.Assessor,
          routeIdentifier: 'ASSESSOR_PUBLISHED',
          claimCategory: 'VERIFY',
        },
        {
          itemName: 'All assessments',
          itemRoute: '/claims/assessor/all',
          minSecurityRole: SecurityRoleKey.Assessor,
          routeIdentifier: 'ASSESSOR_ALL',
          claimCategory: 'ALL_ASSESSMENTS',
        },
      ],
    },
    {
      itemName: 'Training Events',
      itemIconClass: 'fas fa-calendar fa-lg',
      minSecurityRole: SecurityRoleKey.User,
      routeIdentifier: 'EVENTS_CALENDAR',
      subMenu: [
        {
          itemName: 'Events calendar',
          itemTranslationKey: 'trainingCalendar',
          itemRoute: '/events-calendar',
          minSecurityRole: SecurityRoleKey.User,
          routeIdentifier: 'TRAINING-CALENDAR',
        },
        {
          itemName: 'Assessment settings',
          itemTranslationKey: 'trainingCalendarSettings',
          itemRoute: '/training-assessment-settings',
          minSecurityRole: SecurityRoleKey.TrainingCalendarAdmin,
          routeIdentifier: 'TRAINING-CALENDAR-SETTINGS',
          readOnly: [
            SecurityRoleKey.JobRolePeople,
            SecurityRoleKey.JobRoleReqs,
            SecurityRoleKey.JobRoleReqsPeople,
            SecurityRoleKey.OuResponsible,
          ],
        },
      ],
    },
    {
      itemName: 'Matrices',
      itemIconClass: 'fa-solid fa-table-list fa-lg',
      routeIdentifier: 'MATRICES',
      minSecurityRole: SecurityRoleKey.User,
      subMenu: [
        {
          itemName: 'Training matrix',
          itemRoute: '/training-matrix',
          minSecurityRole: SecurityRoleKey.User,
          routeIdentifier: 'ANALYSIS_TRAINING-MATRIX',
        },
        {
          itemName: 'Competency matrix',
          itemRoute: '/competency-matrix',
          minSecurityRole: SecurityRoleKey.User,
          routeIdentifier: 'ANALYSIS_COMPETENCY-MATRIX',
        },
      ],
    },
    {
      itemName: 'Analytics',
      routeIdentifier: 'ANALYSIS',
      itemIconClass: 'fa-solid fa-chart-line fa-lg',
      minSecurityRole: SecurityRoleKey.User,
      subMenu: [
        {
          itemName: 'Job role profile',
          itemRoute: 'my-job-role-profile',
          minSecurityRole: SecurityRoleKey.User,
          routeIdentifier: 'MY-JOB_ROLE_PROFILE',
        },
        {
          itemName: 'Job role analysis',
          itemRoute: '/job-role-analysis',
          minSecurityRole: SecurityRoleKey.OuResponsible,
          routeIdentifier: 'ANALYSIS_JOB-ROLES',
        },
        {
          itemName: 'Competency & training analytics',
          itemRoute: '/competency-training-analytics',
          minSecurityRole: SecurityRoleKey.TrainingCalendarAdmin,
          routeIdentifier: 'ANALYSIS_COMPETENCY-TRAINING-ANALYTICS',
        },
        {
          itemName: 'Training matrix summary',
          itemRoute: '/training-matrix-summary',
          minSecurityRole: SecurityRoleKey.OuResponsible,
          routeIdentifier: 'ANALYSIS_TRAINING-MATRIX-SUMMARY',
        },
      ],
    },
    {
      itemName: 'Projects',
      routeIdentifier: 'Projects',
      itemIconClass: 'fa-solid fa-diagram-project',
      minSecurityRole: SecurityRoleKey.Admin,
      hideItem: true,
      subMenu: [
        {
          itemName: 'View Projects',
          itemRoute: '/view-projects',
          minSecurityRole: SecurityRoleKey.Admin,
          routeIdentifier: 'PROJECTS_VIEW',
        },
        {
          itemName: 'Create A Project',
          itemRoute: '/create-project',
          minSecurityRole: SecurityRoleKey.Admin,
          routeIdentifier: 'PROJECTS_CREATE',
        },
      ],
    },
    {
      itemName: 'Admin',
      itemIconClass: 'fas fa-users-cog',
      minSecurityRole: SecurityRoleKey.JobRolePeople,
      routeIdentifier: 'ADMIN',
      subMenu: [
        {
          itemName: 'User security roles',
          itemRoute: '/user-security-roles',
          minSecurityRole: SecurityRoleKey.Admin,
          routeIdentifier: 'ADMIN_USER-SECURITY-ROLES',
        },
        {
          itemName: 'Role management',
          itemRoute: '/job-roles-list',
          minSecurityRole: SecurityRoleKey.JobRolePeople,
          routeIdentifier: 'ADMIN_JOB-ROLES-LIST',
          readOnly: [SecurityRoleKey.OuResponsible],
        },
        {
          itemName: 'Group management',
          itemRoute: '/group-management',
          minSecurityRole: SecurityRoleKey.JobRolePeople,
          routeIdentifier: 'ADMIN_JOB-ROLE-GROUPS',
          readOnly: [
            SecurityRoleKey.JobRoleReqs,
            SecurityRoleKey.OuResponsible,
          ],
        },
        {
          itemName: 'Capability framework',
          itemRoute: '/capability-framework',
          minSecurityRole: SecurityRoleKey.Admin,
          routeIdentifier: 'ADMIN_CAPABILITY-FRAMEWORK',
        },
        {
          itemName: 'Matrix settings',
          itemRoute: '/matrix-settings',
          minSecurityRole: SecurityRoleKey.OuResponsible,
          routeIdentifier: 'ADMIN_MATRIX-SETTINGS',
          readOnly: [SecurityRoleKey.OuResponsible],
        },
      ],
    },
  ];

  private demoMenu: Array<Subset<MenuItem>> = [
    {
      itemName: 'Projects',
      routeIdentifier: 'Projects',
      hideItem: false,
    },
  ];

  private pilotMenu: Array<Subset<MenuItem>> = [];

  private hfgMenu: Array<Subset<MenuItem>> = [];

  private gmcaMenu: Array<Subset<MenuItem>> = [
    {
      routeIdentifier: 'COMPETENCIES',
      hideItem: true,
    },
    {
      routeIdentifier: 'ASSESSMENTS',
      hideItem: true,
    },
    {
      routeIdentifier: 'MATRICES',
      subMenu: [
        {
          routeIdentifier: 'ANALYSIS_COMPETENCY-MATRIX',
          hideItem: true,
        },
      ],
    },

    {
      routeIdentifier: 'ANALYSIS',
      subMenu: [
        {
          routeIdentifier: 'ANALYSIS_TRAINING-MATRIX-SUMMARY',
          allowManagementPositionAccess: true,
        },
      ],
    },
  ];

  private getingeMenu: Array<Subset<MenuItem>> = [
    {
      routeIdentifier: 'COMPETENCIES',
      hideItem: true,
    },
    {
      routeIdentifier: 'ASSESSMENTS',
      hideItem: true,
    },
    {
      routeIdentifier: 'EVENTS_CALENDAR',
      hideItem: true,
    },
    {
      routeIdentifier: 'MATRICES',
      hideItem: true,
    },
    {
      routeIdentifier: 'ANALYSIS',
      hideItem: true,
    },
    {
      routeIdentifier: 'ADMIN',
      subMenu: [
        {
          hideItem: true,
          routeIdentifier: 'ADMIN_CAPABILITY-FRAMEWORK',
        },
        {
          hideItem: true,
          routeIdentifier: 'ADMIN_MATRIX-SETTINGS',
        },
      ],
    },
  ];

  private mfrsMenu: Array<Subset<MenuItem>> = [];

  private vivoMenu: Array<Subset<MenuItem>> = [];

  private pepperMenu: Array<Subset<MenuItem>> = [
    {
      routeIdentifier: 'COMPETENCIES',
      hideItem: true,
    },
    {
      routeIdentifier: 'ASSESSMENTS',
      hideItem: true,
    },
    {
      routeIdentifier: 'EVENTS_CALENDAR',
      hideItem: true,
    },
    {
      routeIdentifier: 'MATRICES',
      hideItem: true,
    },
    {
      routeIdentifier: 'ANALYSIS',
      subMenu: [
        {
          routeIdentifier: 'ANALYSIS_JOB-ROLES',
          hideItem: true,
        },
        {
          routeIdentifier: 'ANALYSIS_COMPETENCY-TRAINING-ANALYTICS',
          hideItem: true,
        },
        {
          routeIdentifier: 'ANALYSIS_TRAINING-MATRIX-SUMMARY',
          hideItem: true,
        },
      ],
    },
    {
      routeIdentifier: 'ADMIN',
      subMenu: [
        {
          hideItem: true,
          routeIdentifier: 'ADMIN_CAPABILITY-FRAMEWORK',
        },
        {
          hideItem: true,
          routeIdentifier: 'ADMIN_MATRIX-SETTINGS',
        },
      ],
    },
  ];

  getMenu(): Array<MenuItem> {
    const clientMenuSetting = environment.clientAccount.menu;
    let menu;
    switch (clientMenuSetting) {
      case 'demo':
        menu = this.getConstructedMenu(this.demoMenu);
        break;
      case 'pilot':
        menu = this.getConstructedMenu(this.pilotMenu);
        break;
      case 'hfg':
        menu = this.getConstructedMenu(this.hfgMenu);
        break;
      case 'gmca':
        menu = this.getConstructedMenu(this.gmcaMenu);
        break;
      case 'getinge':
        menu = this.getConstructedMenu(this.getingeMenu);
        break;
      case 'vivo':
        menu = this.getConstructedMenu(this.vivoMenu);
        break;
      case 'pepper':
        menu = this.getConstructedMenu(this.pepperMenu);
        break;
      case 'mfrs':
        menu = this.getConstructedMenu(this.mfrsMenu);
        break;
      default: {
        menu = this.getConstructedMenu(this.menu);
        break;
      }
    }
    this.customMenu = menu;
    this.flattenRequiredSecurityRoles = this.flattenRoles(menu);
    return menu;
  }

  private getConstructedMenu(customMenu) {
    const fullMenu = [];
    this.menu.forEach((m) => {
      const customMenuItem = customMenu.find(
        (f) => f.routeIdentifier === m.routeIdentifier,
      );
      const menuItem = Object.assign({}, m, customMenuItem);
      if (customMenuItem && customMenuItem.subMenu) {
        menuItem.subMenu = [];
        m.subMenu.forEach((sm) => {
          const cmi = customMenuItem.subMenu.find(
            (mis) => mis.routeIdentifier === sm.routeIdentifier,
          );
          const smi = Object.assign({}, sm, cmi);
          menuItem.subMenu.push(smi);
        });
      }

      fullMenu.push(menuItem);
    });
    return fullMenu;
  }

  private flattenRoles(menu) {
    const requiredSecurityRole = menu.reduce((m, r) => {
      if (r.routeIdentifier) {
        m[r.routeIdentifier] = r.minSecurityRole;
      }
      if (r.subMenu) {
        r.subMenu.reduce((m1, r1) => {
          if (r1.routeIdentifier) {
            m1[r1.routeIdentifier] = r1.minSecurityRole;
          }
          return m1;
        }, m);
      }
      return m;
    }, {});
    return requiredSecurityRole;
  }
}
