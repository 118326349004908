import { NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { combineLatest } from 'rxjs';
import { AuthService } from '../../../core/services/auth.service';
import { ClientAccountService } from '../../../core/services/client-account.service';
import { MenuItem, Subset } from '../menu-item';
import { NavbarMenuComponent } from './menu/navbar-menu.component';
import { MiniMenuComponent } from './mini-menu/mini-menu.component';

@Component({
  selector: 'ug-navbar',
  standalone: true,
  imports: [NgIf, NavbarMenuComponent, MiniMenuComponent],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
  @Input() inUserGroup = false;
  @Input() navType = 'sideNav';
  @Input() isCollapsed: boolean;

  private authService: AuthService = inject(AuthService);
  private clientAccountService: ClientAccountService =
    inject(ClientAccountService);

  protected menuData: Array<MenuItem | Subset<MenuItem>> = [];
  protected userSecurityLevel: number;
  protected personHasManagementPosition = false;
  protected claimNotificationMap = new Map<string, number>();
  protected roleLookup: any;
  protected menuLoading = true;
  protected subMenu: string;
  protected personIsAssessor: boolean;

  ngOnInit() {
    this.menuLoading = true;

    combineLatest([
      this.authService.securityRoleLookupSubj,
      this.authService.loggedInUserSubj,
    ]).subscribe({
      next: ([srl, usd]) => {
        this.roleLookup = srl;
        this.userSecurityLevel = usd.securityLevel;
        this.personHasManagementPosition = usd.isManager;
        this.personIsAssessor = usd.isAssessor;
        this.menuData = [...this.clientAccountService.getMenu()]
          .filter((mi) => this.showMenuItem(mi))
          .map((element) => {
            return {
              ...element,
              subMenu: element.subMenu?.filter((subElement) =>
                this.showMenuItem(subElement),
              ),
            };
          });
        this.menuLoading = false;
      },
    });
  }

  showMenuItem(menuItem: any): boolean {
    if (menuItem.hideItem) {
      return false;
    }

    const hasRequiredSecurityLevel =
      this.roleLookup[menuItem.minSecurityRole].securityLevel <=
      this.userSecurityLevel;

    const hasManagementAccess =
      menuItem.allowManagementPositionAccess &&
      this.personHasManagementPosition;

    if (menuItem.forAssessorOnly) {
      return hasRequiredSecurityLevel && this.personIsAssessor;
    }

    return hasRequiredSecurityLevel || hasManagementAccess;
  }
}
