import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BrowserStorageService } from '@utils';
import {
  Observable,
  Subject,
  Subscription,
  first,
  fromEvent,
  merge,
  timer,
} from 'rxjs';
import { ConfigService } from '../services/config.service';

@Injectable({
  providedIn: 'root',
})
export class InactivityTimeoutService {
  private readonly configService = inject(ConfigService);
  private readonly browserStorageService = inject(BrowserStorageService);
  private readonly destroy = inject(DestroyRef);

  idleEvents: Observable<any> = new Observable();
  timer: Subscription = new Subscription();

  inactiveSecondsCount: Subject<number> = new Subject<number>();
  startIdleWatch: Subject<boolean> = new Subject<boolean>();

  constructor() {
    this.idleEvents = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keydown'),
      fromEvent(document, 'keyup'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize'),
    );
  }

  watchForUserIdle() {
    this.idleEvents.pipe(takeUntilDestroyed(this.destroy)).subscribe(() => {
      this.restartTimer();
    });
  }

  restartTimer() {
    this.timer.unsubscribe();
    this.browserStorageService.remove('logoutTime');
    this.startTimer();
  }

  startWatch() {
    this.startIdleWatch
      .pipe(first(), takeUntilDestroyed(this.destroy))
      .subscribe((val) => {
        if (val) {
          this.restartTimer();
          this.watchForUserIdle();
        }
      });
  }

  startTimer() {
    let logoutTime =
      Math.round(new Date().getTime() / 1000) +
      this.configService.inactivity_timeout_logout_seconds;
    this.browserStorageService.set('logoutTime', logoutTime.toString());

    this.timer = timer(1000, 1000).subscribe(() => {
      let timeRemaining =
        parseInt(this.browserStorageService.get('logoutTime'), 10) -
        Math.round(new Date().getTime() / 1000);

      this.inactiveSecondsCount.next(
        this.configService.inactivity_timeout_logout_seconds - timeRemaining,
      );
    });
  }
}
