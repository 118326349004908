import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, catchError, map, throwError } from 'rxjs';
import { ConfigService } from '../../core/services/config.service';

export interface PersonJobRoles {
  personId: number;
  jobRoles: Array<{
    jobRoleDescription: string;
    jobRoleName: string;
    jobRoleId: number;
    orgUnitId: number;
    orgUnitName: string;
  }>;
}

export interface PersonVerificationAreas {
  personId: number;
  verificationAreas: Array<{
    funcAreaDescription: string;
    funcAreaId: number;
    funcAreaName: string;
    maxApprovalLevel: number;
  }>;
}

export interface PersonDirectReports {
  personId: number;
  directReports: Array<{
    personId: number;
    displayName: string;
    jobRoles: Array<{
      jobRoleId: number;
      jobRoleName: string;
    }>;
  }>;
}

export interface PersonBasic {
  id: number;
  name: string;
  displayName: string;
  externalId: number;
  externalUserId: number;
}

export interface ExternalActivePerson {
  id: number;
  name: string;
  displayName: string;
  externalId: number;
  externalUserId: number;
  location: string;
  orgUnitNames: string;
  jobRoleNames: string;
}

interface PersonJobRolesResponse {
  personJobRoles: PersonJobRoles;
}

export interface PersonSearchParameters {
  isViewUserData?: boolean;
  orgUnitTypeId?: number;
  orgUnitId?: number;
  allowTrainingCalendarAdmin?: boolean;
  securityRoleId?: number;
  source?: string;
  externalTypeId?: string;
  operator?: string;
  search?: string;
  maxCount?: number;
  page?: number;
  pageSize?: number;
  minSecurityLevel?: number;
  order?: string;
}

export interface PersonLookup {
  hierarchyKey: string;
  key: string;
  name: string;
  parentKey: string;
}

export interface PersonSecrityRole {
  adUpn: string;
  securityRoleId: number;
  securityLevel: number;
  orgUnits: { id: number; name: string }[];
  id: number;
  name: string;
  displayName: string;
  externalId: any;
  externalUserId: any;
}

export interface SecurityRoleUpdate {
  securityRoleId: number;
  orgUnitIds: number[];
}

export interface PagedPersonSecurityRoleSearch {
  data: PersonSecrityRole[];
  page: number;
  pageSize: number;
  totalPageCount: number;
  totalRowCount: number;
}

export interface PersonSubordinate {
  displayName: string;
  id: number;
  jobRoles: { id: number; name: string }[];
}

const dotNetApiServerUri = environment.serverUrl;

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  private http = inject(HttpClient);
  private config = inject(ConfigService);

  updatePersonSecurityRole(
    personId: number,
    securityRoleUpdate: SecurityRoleUpdate,
  ) {
    const url = `${dotNetApiServerUri}/people/${personId}/securityRole`;
    return this.http.post(url, securityRoleUpdate).pipe(
      map((resp) => resp),
      catchError((err) => throwError(() => new Error(err))),
    );
  }

  search(payload: PersonSearchParameters): Observable<any> {
    const url = `${dotNetApiServerUri}/people/search`;
    return this.http.post(url, JSON.stringify(payload)).pipe(
      map((resp) => resp),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  searchForSecurityRoles(
    payload: PersonSearchParameters,
  ): Observable<PersonSecrityRole[]> {
    const url = `${dotNetApiServerUri}/people/forSecurityRoles`;
    return this.http
      .post<PersonSecrityRole[]>(url, JSON.stringify(payload))
      .pipe(
        map((resp) => resp),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  searchForSecurityRolesPaged(
    payload: PersonSearchParameters,
  ): Observable<PagedPersonSecurityRoleSearch> {
    const url = `${dotNetApiServerUri}/people/forSecurityRoles/paged`;
    return this.http.post<PagedPersonSecurityRoleSearch>(
      url,
      JSON.stringify(payload),
    );
  }

  getExternalActivePeople(): Observable<ExternalActivePerson[]> {
    const url = `${dotNetApiServerUri}/people/externalActive`;
    return this.http.get<ExternalActivePerson[]>(url).pipe(
      map((resp) => resp),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getPersonLookup(payload: PersonSearchParameters): Observable<PersonLookup[]> {
    return this.http
      .post<
        PersonLookup[]
      >(`${dotNetApiServerUri}/people/lookups`, JSON.stringify(payload))
      .pipe(
        map((resp) => resp),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getPersonSearchPaged(
    payload: PersonSearchParameters,
  ): Observable<PersonBasic[]> {
    return this.http
      .post<
        PersonBasic[]
      >(`${dotNetApiServerUri}/people/search/paged`, JSON.stringify(payload))
      .pipe(
        map((resp) => resp),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getPersonSubordinates(): Observable<PersonSubordinate[]> {
    return this.http.get<PersonSubordinate[]>(
      `${dotNetApiServerUri}/people/subordinates`,
    );
  }

  getPersonPhoto(
    size:
      | '48x48'
      | '64x64'
      | '96x96'
      | '120x120'
      | '240x240'
      | '360x360'
      | '432x432'
      | '504x504'
      | '648x648',
  ): Observable<Blob> {
    return this.http.get(`${dotNetApiServerUri}/people/photo/${size}`, {
      responseType: 'blob',
    });
  }
}
